import type { IBooking, ISegment } from "@hotel-engine/types/trips";
import * as Styled from "../../TripsListView/styles";
import { AirlineLogo } from "@hotel-engine/assets/flights/AirlineLogo";
import { useAirlineInfo } from "../../../Flights/hooks/useAirlineInfo";
import { useAcrissInfo } from "../../../Cars/hooks/useAcrissInfo";
import { Box, Chip } from "@hotelengine/atlas-web";
import WrapInStrong from "../WrapInStrong";

const TripDetailCell = ({ data, searchValue }: { data: IBooking; searchValue: string }) => {
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();
  const { getAcrissCategory } = useAcrissInfo();

  const bookingType = data.bookingType;
  const details = data.details;
  const roomCount = data?.roomCount || 0;
  const roomCountCopy = `${roomCount} rooms`;
  const flightConfirmation = data.details.flightConfirmation;

  const showPendingModChip = !!data.details.bookingStatuses?.pendingModifications;

  const PendingModificationChip = () =>
    showPendingModChip ? (
      <Box paddingTop={6}>
        <Chip color="yellow" label="Request pending" size="xs" />
      </Box>
    ) : null;

  if (bookingType === "flight") {
    if (!details?.slices?.length) {
      return <Styled.PrimaryText>No flight details available</Styled.PrimaryText>;
    }

    const airlinesDisplay = new Set(
      details.slices[0].segments.reduce((acc: string[], cur: ISegment) => {
        acc.push(cur.origin.carrierCode);
        return acc;
      }, [])
    );

    const airlinesCopy =
      airlinesDisplay.size > 1
        ? "Multiple carriers"
        : getAirlineNameFromIataAirlineCode([...airlinesDisplay][0]);

    return (
      <Styled.TripDetailWrapper>
        <AirlineLogo iata={[...airlinesDisplay]} size={32} />
        <Box marginLeft={4}>
          <Styled.PrimaryText>
            {airlinesCopy}
            {"  •  "}
            {details?.slices[0].segments[0].origin.carrierCode}
            {details?.slices[0].segments[0].origin.flightNumber}
          </Styled.PrimaryText>
          <Styled.SubText>#{flightConfirmation}</Styled.SubText>
          <PendingModificationChip />
        </Box>
      </Styled.TripDetailWrapper>
    );
  }

  if (bookingType === "car") {
    if (!details.rentalCompany) {
      return <Styled.PrimaryText>No car details available</Styled.PrimaryText>;
    }

    const textLabel = details.car
      ? `${getAcrissCategory(details.car?.type) || "Rental"} car`
      : details.rentalCompany.name;

    return (
      <Styled.TripDetailWrapper>
        <Styled.CarImageContainer>
          <Styled.CarTripImage src={details.rentalCompany?.logo} />
        </Styled.CarImageContainer>
        <div>
          <Styled.PrimaryText>{textLabel}</Styled.PrimaryText>
          <Styled.SubText>#{details.carConfirmation}</Styled.SubText>
          <PendingModificationChip />
        </div>
      </Styled.TripDetailWrapper>
    );
  }

  if (bookingType === "lodging") {
    if (!details?.property) {
      return <Styled.PrimaryText>No lodging details available</Styled.PrimaryText>;
    }

    return (
      <Styled.TripDetailWrapper>
        <Styled.ImageContainer>
          <Styled.TripImage src={details?.property.imageUrl} />
        </Styled.ImageContainer>
        <div>
          <Styled.PrimaryText>
            <WrapInStrong term={searchValue} target={details?.property.name} />
          </Styled.PrimaryText>
          <Styled.SubText>
            {roomCount > 1 ? roomCountCopy : details.internalConfirmationNumber}
          </Styled.SubText>
          <PendingModificationChip />
        </div>
      </Styled.TripDetailWrapper>
    );
  }
  return null;
};

export default TripDetailCell;
